import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    utilizator: null,
    password: null
  },
  getters: {
    getUtilizatorState: state => state.utilizator,
    getPasswordState: state => state.password
  },
  mutations: {
    setUtilizatorState ( state, value ) {
      state.utilizator = value
    },
    setPasswordState ( state, value ) {
      state.password = value
    }
  },
  actions: {
  },
  modules: {
  }
})
