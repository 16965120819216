<template>
  <v-app id="app">
    <v-snackbar
        v-model="snackbar"
        :color="snackbarColor"
    >
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
                Inchide
            </v-btn>
        </template>
    </v-snackbar>
    <router-view/>
  </v-app>
</template>

<script>
export default {
    created () {
        this.$root.$on( 'snackbarFire', json => {
            this.snackbarText = json.snackbarText;
            this.snackbar = json.snackbar;
            this.snackbarColor = json.snackbarColor;
        } )
    },
    data: () => ({
        snackbarText: '',
        snackbar: false,
        snackbarColor: 'success'
    })
}
</script>

<style>

</style>
