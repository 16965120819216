<template>
    <div class="login-screen">
        <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
        >
            {{ snackbarText }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Inchide
                </v-btn>
            </template>
        </v-snackbar>

        <v-form
            v-model="valid"
            ref="form"
            @submit.prevent="submit"
        >
             <v-img
                class="mb-6"
                height="80"
                contain
                src="../assets/topk-logo-transparent.png"
            ></v-img>
            <v-text-field
                v-model="utilizator"
                filled
                :rules="[rules.required]"
                autocomplete="off"
                label="Nume utilizator"
                name="utilizator-topk"
            ></v-text-field>
            <v-text-field
                v-model="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="show ? 'text' : 'password'"
                name="input-10-1"
                filled
                label="Parola"
                autocomplete="off"
                @click:append="show = !show"
            ></v-text-field>
            <v-btn
                block
                color="primary"
                type="submit"
            >
                Autentificare
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import store from '../store';

export default {
    name: 'login-page',
    data: () => ({
        valid: null,
        utilizator: null,
        password: null,
        show: false,
        snackbarText: '',
        snackbar: false,
        snackbarColor: 'success',
        rules: {
            required: value => !!value || 'Obligatoriu.'
        }
    }),
    methods: {
        submit(event) {
            event.preventDefault();

            store.commit( 'setUtilizatorState', this.utilizator );
            store.commit( 'setPasswordState', this.password );

            const base = 'https://topk.dev-factory.ro/topk-app/php/topk-login.php';
            axios.post(base, `${this.utilizator}, ${this.password}` ).then(response => {
                
                if (typeof response.data === 'string') {
                    this.$router.push({ name: 'sort-products' });
                    this.$root.$emit( 'snackbarFire', {
                        snackbarColor: 'success',
                        snackbar: true,
                        snackbarText: 'Autentificare cu success.'
                    } );
                    document.cookie = "topkToken=" + response.data;
                    document.cookie = "topkU=" + this.utilizator;
                    document.cookie = "topkP=" + this.password;
                } else {
                    this.$root.$emit( 'snackbarFire', {
                        snackbarColor: 'error',
                        snackbar: true,
                        snackbarText: 'Introdu datele corecte pentru autentificare.'
                    } );
                }
            });
        }
    }
}
</script>

<style>
    .login-screen {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>