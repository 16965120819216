import Vue from 'vue'
import VueRouter from 'vue-router'
import LogInPage from '../views/LogInPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login-page',
    component: LogInPage
  }, {
    path: '/sort-products',
    name: 'sort-products',
    component: () => import('../views/SortProducts.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
